import { createFeature, createReducer, on } from '@ngrx/store';
import { SkillActions } from './skill.actions';
import { Skill } from '../../models';

export const skillFeatureKey = 'skill';

export interface SkillState {
  skill: Skill | null;
  skills: Skill[];
  loading: boolean;
  error: any;
}

export const initialState: SkillState = {
  skill: null,
  skills: [],
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    SkillActions.loadSkills,
    (state): SkillState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    SkillActions.loadSkillsSuccess,
    (state, { skills }): SkillState => ({
      ...state,
      skills: [...state.skills.filter((item) => !skills.find(x => x.id === item.id && x.agency?.id === item.agency?.id)), ...skills],
      loading: false,
      error: null,
    }),
  ),
  on(
    SkillActions.loadSkillsFailure,
    (state, { error }): SkillState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    SkillActions.loadSkill,
    (state): SkillState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    SkillActions.loadSkillSuccess,
    (state, { skill }): SkillState => ({
      ...state,
      skills: [...state.skills.filter((item) => skill.id === item.id && skill.agency?.id === item?.agency?.id), skill],
      skill: skill,
      loading: false,
      error: null,
    }),
  ),
  on(
    SkillActions.loadSkillFailure,
    (state, { error }): SkillState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const skillFeature = createFeature({
  name: skillFeatureKey,
  reducer,
});

