import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Skill } from '../../models';

export const SkillActions = createActionGroup({
  source: 'Skill',
  events: {
    'Load Skills': props<{ agencyId: string }>(),
    'Load Skills Success': props<{ skills: Skill[] }>(),
    'Load Skills Failure': props<{ error: any }>(),
    'Load Skill': props<{ skillId: string; agencyId: string }>(),
    'Load Skill Success': props<{ skill: Skill }>(),
    'Load Skill Failure': props<{ error: any }>(),
  }
});
